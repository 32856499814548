import React, { Component } from "react";

// Components
import { TimestampToDate } from "@utils/date.js";
import { threeDecimal } from "@utils/format";

import "./table.css";

class Page extends Component {
  componentDidMount() {
    if (typeof this.props.handleDidMount == "function") {
      this.props.handleDidMount();
    }
  }

  render() {
    const { table, date, posto, serie, tpv, cliente, search } = this.props;

    return (
      <table style={{ width: "100%" }}>
        <tr>
          <th colSpan="10">
            <table style={{ width: "100%", borderBottom: '1px solid black' }}>
              <tr>
                <th>Data Inicial</th>
                <th>Data Final</th>
                <th>Posto</th>
                <th>Serie</th>
                <th>TPV</th>
                <th>Cliente</th>
                <th>Pesquisa</th>
              </tr>
              <tr>
                <td>{TimestampToDate(date[0] / 1000).split(" ")[0]}</td>
                <td>{TimestampToDate(date[1] / 1000).split(" ")[0]}</td>
                <td>{posto.label}</td>
                <td>{serie.label}</td>
                <td>{tpv.label}</td>
                <td>{cliente.label}</td>
                <td>{search}</td>
              </tr>
            </table>
          </th>
        </tr>
        <tr>
          <th colSpan="9">&nbsp;</th>
          <th style={{ border: "1px solid black", borderBottom: 0 }}>Total</th>
        </tr>
        <tr>
          <td colSpan="9">&nbsp;</td>
          <td style={{ border: "1px solid black", borderTop: 0 }}>
            {threeDecimal(table.rows.reduce((acc, row) => acc + parseFloat(row.total), 0))}
          </td>
        </tr>
        <tr>
          {table.columns.map(({ Header }, index) => (
            <th>{Header}</th>
          ))}
        </tr>
        {table.rows.map((row) => (
          <tr>
            <td>{row.date}</td>
            <td>{row.posto_name || row.posto}</td>
            <td>{row.serie}</td>
            <td>{row.tpv}</td>
            <td>{row.talao}</td>
            <td>{row.customer_name}</td>
            <td>{row.matricula}</td>
            <td>{row.kilometros}</td>
            <td>{row.requisicao}</td>
            <td>{row.total}</td>
          </tr>
        ))}
      </table>
    );
  }
}

export default Page;
