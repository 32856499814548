import React, { Component } from 'react';

// Components
import { threeDecimal } from "@utils/format";
import { TimestampToDate } from "@utils/date.js";

import './table.css';

class Page extends Component {
    componentDidMount() {
        if (typeof this.props.handleDidMount == 'function') {
            this.props.handleDidMount();
        }
    }

    render () {
        const { table, date, posto, cliente, pagamento } = this.props;

        return (
            <table style={{ width: '100%' }}>
                <tr>
                    <th colSpan="11">
                        <table style={{ width: '100%', borderBottom: '1px solid black' }}>
                            <tr>
                                <th>Data Inicial</th>
                                <th>Data Final</th>
                                <th>Cliente</th>
                                <th>Posto</th>
                                <th>Pagamento</th>
                            </tr>
                            <tr>
                                <td>{TimestampToDate(date[0] / 1000).split(" ")[0]}</td>
                                <td>{TimestampToDate(date[1] / 1000).split(" ")[0]}</td>
                                <td>({cliente.value}) {cliente.label}</td>
                                <td>({posto.value}) {posto.label}</td>
                                <td>({pagamento.value}) {pagamento.label}</td>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th colSpan="10">&nbsp;</th>
                    <th style={{ border: "1px solid black", borderBottom: 0 }}>Total</th>
                </tr>
                <tr>
                    <td colSpan="10">&nbsp;</td>
                    <td style={{ border: "1px solid black", borderTop: 0 }}>
                    {threeDecimal(table.rows.reduce((acc, row) => acc + parseFloat(row.total), 0))}
                    </td>
                </tr>
                <tr>
                    {table.columns.filter(({ accessor }) => accessor !== 'inventory').filter(({ print }) => print != false).map(({ Header }, index) => (
                        <th style={{
                            textAlign: [4].includes(index) ? 'left' : [5,6,8,9,10].includes(index) ? 'right' : 'center',
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ Header }</th>
                    ))}
                </tr>
                {table.rows.map((row) => (
                    <tr>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.cliente }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ `${row.posto}${row.tpv}${row.serie}/${row.talao}` }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.data }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.produto }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                            textAlign: 'left',
                        }}>{ row.designacao }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                            textAlign: 'right',
                        }}>{ row.qnt }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                            textAlign: 'right',
                        }}>{ row.total }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                        }}>{ row.matricula }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                            textAlign: 'right',
                        }}>{ row.kilometros }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                            textAlign: 'right',
                        }}>{ row.kilometros_actuais }</td>
                        <td style={{
                            fontFamily: '"Arial" , sans-serif',
                            fontSize: '12px',
                            textAlign: 'right',
                        }}>{ row.media }</td>
                    </tr>
                ))}
            </table>
        );
    }
}

export default Page;