import React from 'react';
import classNames from 'classnames';
import EmptyLine from './EmptyLine';

class Submenu extends React.Component {

    render () {
        const { title, submenus, width } = this.props;

        return (
            <>
                <li
                    className={classNames({
                        'visible-xs': false,
                        'visible-sm': false,
                        'visible-md': false,
                        'visible-lg': true,
                        'dropdown': true
                    })}
                    id="menu2"
                >
                    <button type="button" className="btn btn-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false" style={{ padding: '14px' }}>
                        { title }
                        <b className={classNames({ 'caret': true })}></b>
                    </button>
                    <ul className={classNames({ 'dropdown-menu': true })}>
                        {submenus.map((submenu) => submenu.condition && submenu.disabled ? (
                            <>
                                <li>
                                    <a onclick="return false;" style={{ fontWeight: 'bold', pointerEvents: 'none', cursor: 'default' }}></a>
                                </li>
                                <li>
                                    <a onclick="return false;" style={{ fontWeight: 'bold', pointerEvents: 'none', cursor: 'default' }}>{ submenu.title }</a>
                                </li>
                            </>
                        ): (
                            <li>
                                <a href={ submenu.url }>{ submenu.title }</a>
                            </li>
                        ))}
                    </ul>
                </li>
                <li
                    className={classNames({
                        'visible-xs': false,
                        'visible-sm': true,
                        'visible-md': true,
                        'visible-lg': false,
                        'dropdown': true
                    })}
                    id="menu2"
                >
                    <select
                        onChange={ (ev) => window.location.href = ev.target.value }
                        style={{
                            height: '47px',
                            width,
                            border: '0'
                        }}
                        >
                        <option disabled selected value>{ title } &#x2B07;</option>
                        {submenus.map((submenu) => submenu.condition && submenu.disabled ? (
                            <>
                                <option disabled></option>
                                <option disabled>{ submenu.title }</option>
                            </>
                        ): (
                            <option value={ submenu.url }>{ submenu.title }</option>
                        ))}
                    </select>
                </li>
                <EmptyLine />
                <li
                    className={classNames({
                        'visible-xs': true,
                        'visible-sm': false,
                        'visible-md': false,
                        'visible-lg': false,
                        'dropdown': true,
                    })}
                    id="menu1"
                >
                    <b>{ title }</b>
                </li>
                <EmptyLine />
                {submenus.map((submenu) => submenu.condition && submenu.disabled ? (
                    <>
                        <li
                            className={classNames({
                                'visible-xs': true,
                                'visible-sm': false,
                                'visible-md': false,
                                'visible-lg': false,
                                'dropdown': true,
                            })}
                            id="menu1"
                        >
                            <a onclick="return false;" style={{ fontWeight: 'bold', pointerEvents: 'none', cursor: 'default' }}></a>
                        </li>
                        <li
                            className={classNames({
                                'visible-xs': true,
                                'visible-sm': false,
                                'visible-md': false,
                                'visible-lg': false,
                                'dropdown': true,
                            })}
                            id="menu1"
                        >
                            <a onclick="return false;" style={{ fontWeight: 'bold', pointerEvents: 'none', cursor: 'default' }}>{ submenu.title }</a>
                        </li>
                    </>
                ): (
                    <li
                        className={classNames({
                            'visible-xs': true,
                            'visible-sm': false,
                            'visible-md': false,
                            'visible-lg': false,
                            'dropdown': true,
                        })}
                        id="menu1"
                    >
                        <a href={ submenu.url }>{ submenu.title }</a>
                    </li>
                ))}
                <EmptyLine />
            </>
        )
    }
}

export default Submenu;